import useSWR from "swr";
import { useMemo } from "react";

import { GridSortModel, GridFilterModel, GridPaginationModel } from "@mui/x-data-grid";

import axios, { fetcher, endpoints } from "src/utils/axios";

import { Report } from "src/types";

const URL = endpoints.reports;

export function useListReports() {

  const { data, mutate, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      reports: (data || []) as Report[],
      mutate,
      isLoading,
      error,
      isValidating,
      isEmpty: !isLoading && !data?.length,
    }),
    [data, mutate, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetReport(id:any) {
  
  const request = id && `${URL}/${id}`;
  const { data, mutate, isLoading, error, isValidating } = useSWR(request, fetcher);

  const memoizedValue = useMemo(
    () => ({
      report: data as Report,
      mutate,
      reportLoading: isLoading,
      reportError: error,
      reportValidating: isValidating,
      reportEmpty: !isLoading && !data?.length,
    }),
    [data, mutate, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function createReport(report: Report) {
  return axios.post(URL, report);
}

export function updateReport(report: Report) {
  return axios.put(`${URL}/${report.id}`, report);
}

export function saveReport(report: Report) {
  return report.id ? updateReport(report) : createReport(report);
}

export function deleteReport(id: number) {
  return axios.delete(`${URL}/${id}`);
}

export async function runReport(id: number, params: any, filter: GridFilterModel, pagination: GridPaginationModel|null, sort: GridSortModel|null, output: 'json' | 'csv') {
  if (id) {
    const response = await axios.post(`${URL}/run`, { id, params, filter, pagination, sort, output }, {});
    return response.data;
  }
  return output === 'csv' ? '' : { count: 0, rows: [] };
}

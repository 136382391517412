import { format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null | undefined;

export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

/**
 * deserialize a date object from a string
 * @param s string
 * @returns the date object
 */

export function stringToDate(s:string) {
  return s ? new Date(s) : null;
}

/**
 * serialize a date as string
 * @param date the date
 * @returns the date as string
 */
export function dateToString(date:Date|string) {
  if (!date) {
    return null;
  }
  if (typeof date === 'string' || date instanceof String) {
    return date;
  }
  return format(date, 'yyyy-MM-dd');
}

/**
 * serialize a date as string with time information
 * @param date the date
 * @returns the datetime as string
 */
export function dateTimeToString(date?:Date|string|null): string | null {
  if (!date) {
    return null;
  }
  if (typeof date === 'string') {
    return date;
  }
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date.toISOString();
}

/**
 * format a date object or string to be displayed on the UI
 * @param d date
 * @returns the formatted date
 */
export const formatDate = (d:any) => d ? new Date(d).toLocaleDateString() : '-';

/**
 * format a date object or string with time information to be displayed on the UI
 * @param d date
 * @returns the formatted date with time information
 */
export const formatDateTime = (d:any) => d ? new Date(d).toLocaleString([], { dateStyle: 'short', timeStyle: 'short' }) : '-';
